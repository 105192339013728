import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

class PostTemplate extends Component {

  images(images) {
    return images
      .map(images =>
        <Img className="mb-70" alt={images.title} key={images.id} fluid={images.localFile.childImageSharp.fluid} />
      )
  }

  movieOrImage() {
    const post = this.props.data.wordpressPost
    // console.log(post)
    if (post.acf.movie) {
      let posterURL = ''
      if (post.featured_media) {
        posterURL = post.featured_media.localFile.publicURL
      } else {
        posterURL = null
      }
      return (
        <video className="w-full mb-85 md:mb-100 xl:mb-150" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={posterURL}>
          <source src={post.acf.movie.localFile.publicURL} type="video/mp4"/>
        </video>
      )
    } else if(post.featured_media) {
      return (
        <div>
          <Img fluid={post.featured_media.localFile.childImageSharp.fluid}  className="mb-85 md:mb-100 xl:mb-150" />
        </div>
      )
    }
  }


  getKeywords() {
    let categories = this.props.data.wordpressPost.categories;
    let array = [];
    for (let index = 0; index < categories.length; index++) {
      const category = categories[index];
      array.push(
        category.name
      );
    }
    return array;
  }


  render() {
    const post = this.props.data.wordpressPost
    
    return (
      <Layout footerfixed={true}>
        <SEO
          title={post.title}
          description={post.ingress}
          image={(post.featured_media ? post.featured_media.localFile.publicURL : null )}
          keywords={this.getKeywords()}
        />
        <main className="container px-5 xl:px-0 pt-70 md:pt-100 xl:pt-150 pb-100 lg:pb-150 wide:pb-250">
          {this.movieOrImage()}
          <div className="flex flex-wrap">
            <div className="w-full">
              <h2 className="subject-small" dangerouslySetInnerHTML={{ __html: post.categories[0].name }} />
            </div>
            <div className="w-full lg:w-1/2 text-white">
              <article>
                <h1 className="title-lg mb-70" dangerouslySetInnerHTML={{ __html: post.title }} />
                <div className="text-standard" dangerouslySetInnerHTML={{ __html: post.content }} />
              </article>
            </div>
            <div className="w-full lg:w-1/2 text-white pl-32">
              {(post.acf.gallery) ? this.images(post.acf.gallery) : null }
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      wordpress_id
      featured_media {
        localFile {
            publicURL
            childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
      }
      acf {
        ingress
        movie {
          localFile {
            publicURL
          }
        }
        gallery {
          id
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      categories {
          wordpress_id
          name
          slug
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`